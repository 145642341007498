<template>
  <Purchase />
</template>

<script>
import Purchase from '@/components/Purchase/courier'

export default {
  name: 'purchase.courier',
  components: {
    Purchase,
  },
}
</script>
